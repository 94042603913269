import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Box, IconButton, Menu, MenuItem, Checkbox, TableSortLabel, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import ProgressBar from './ProgressBar';
// Helper functions remain the same (descendingComparator, getComparator, stableSort)
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import OutboundIcon from '@mui/icons-material/Outbound';


const PatientTable = ({ role, patients, onRead, onDelete, onContractUpdate }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('fullname');
    const [searchQuery, setSearchQuery] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [visibleColumns, setVisibleColumns] = useState({
        iin: false,
        fullname: true,
        progress: true,
        actions: true
    });


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSettingsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (column) => {
        setVisibleColumns(prev => ({
            ...prev,
            [column]: !prev[column]
        }));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    

    const filteredRows = patients.filter((patient) => {
        return (
            patient.iin?.includes(searchQuery.toLowerCase()) ||
            patient.fullname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            patient.company_enroller?.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    // Grouping patients by company_enroller with a single contract_id
    const groups = filteredRows.reduce((groups, patient) => {
        // Check if group for the company_enroller exists, if not initialize it
        if (!groups[patient.company_enroller]) {
            groups[patient.company_enroller] = {
                company_enroller: patient.company_enroller,
                contract_id: patient.contract_id,
                patients: []
            };
        }

        // Add the patient to the patients array
        groups[patient.company_enroller].patients.push(patient);

        return groups;
    }, {});

    // Convert grouped patients to an array for easier processing
    const groupedPatients = Object.values(groups);



    return (
        <Box>
            <Box sx={{ display: 'flex', gap: 2, marginTop: 2, justifyContent: 'space-between' }}>
                <TextField
                    label="Поиск"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <IconButton onClick={handleSettingsClick}>
                    <SettingsIcon />
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {Object.keys(visibleColumns).map((column) => (
                    <MenuItem key={column} onClick={() => handleMenuItemClick(column)}>
                        <Checkbox checked={visibleColumns[column]} />
                        {column.charAt(0).toUpperCase() + column.slice(1)}
                    </MenuItem>
                ))}
            </Menu>
            {groupedPatients.map(group => (
                <Accordion key={group.contract_id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {/* <IconButton onClick={() => onContractUpdate(group.contract_id)} sx={{paddingTop: 0}}>
                            <OutboundIcon sx={{maxHeight:1}} />
                        </IconButton> */}
                        <Typography>{group.company_enroller}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        {visibleColumns.iin && <TableCell>ИИН Пациента</TableCell>}
                                        {visibleColumns.fullname && <TableCell>ФИО Пациента</TableCell>}
                                        {visibleColumns.progress && <TableCell>Прогресс</TableCell>}
                                        {visibleColumns.actions && <TableCell>Действия</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {group.patients.map((patient, index) => (
                                        <TableRow key={index}>
                                            {visibleColumns.iin && <TableCell>{patient.iin}</TableCell>}
                                            {visibleColumns.fullname && <TableCell>{patient.fullname}</TableCell>}
                                            {visibleColumns.progress && (
                                                <TableCell>
                                                    <ProgressBar assigned={patient.assigned} />
                                                </TableCell>
                                            )}
                                            {visibleColumns.actions && (
                                                <TableCell>
                                                    <IconButton onClick={() => onRead(patient)}>
                                                        <VisibilityIcon color="info"/>
                                                    </IconButton>
                                                    {/* <IconButton onClick={() => onDelete(patient)}>
                                                        <DeleteIcon color="error"/>
                                                    </IconButton> */}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default PatientTable;
