import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Box, Collapse, IconButton, Checkbox, FormControlLabel, Tabs, Tab, Select, MenuItem } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import specialistsProcedures from '../../config/specialists-procedures';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import ProgressBar from './ProgressBar';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';

const correctDateForTimezone = (dateString) => {
    const date = new Date(dateString);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset).toISOString().slice(0, 10);
};

const RolesTranslated = {
    "Регистратор": "registrator",
    "Терапевт": "therapist",
    "Офтальмолог": "ophthalmologist",
    "Отоларинголог": "otolaringologist",
    "Хирург": "surgeon",
    "Невролог": "neurologist",
    "Дерматолог": "dermatologist",
    "Гинеколог": "gynecologist",
};


const PatientModalTabs = ({ open, onClose, patients, role, onRoleSpecificAction }) => {
    const [expanded, setExpanded] = useState(true);
    const [assignedProcedures, setAssignedProcedures] = useState([]);
    const [patientInfoChanged, setPatientInfoChanged] = useState(false);
    const [comment, setComment] = useState('');
    const [patientInfo, setPatientInfo] = useState({});
    const { auth } = useAuth();
    const [newProcedure, setNewProcedure] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);

    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();

    const handleTabChange = (event, newValue) => {
        setComment('');
        setPatientInfoChanged(false);
        setPatientInfo({});
        setSelectedTab(newValue);
    };

    useEffect(() => {
        const patient = patients[selectedTab];
        setPatientInfo(patient);
        if (patients && patients.length > 0) {
            setAssignedProcedures(patient.assigned);
            patient.comments?.map(comment => {
                if( comment.doctor_name === auth?.fullname) {
                    setComment(comment.comment_content);
                }
            });
        }
    }, [patients, selectedTab]);


    
    const handleAddProcedure = () => {
        const newP = newProcedure.trim();
        if (newP && !Object.keys(assignedProcedures)?.filter(procedure => procedure === newP).length) {
            setAssignedProcedures({...assignedProcedures, [newP]: null});
            setNewProcedure('');
        }
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleCheckboxChange = (procedureName) => {
        setAssignedProcedures(prevState =>
            prevState.map(proc =>
                proc.procedure === procedureName
                    ? { ...proc, date: proc.date ? null : new Date().toISOString().split('T')[0] }
                    : proc
            )
        );
    };

    const checkAllProcedures = (filteredProcedures) => {
        // console.log(filteredProcedures); // Debugging to verify input
        setAssignedProcedures(prevState =>
            prevState.map(proc =>
                filteredProcedures.find(filteredProc => filteredProc.procedure === proc.procedure)
                    ? { ...proc, date: new Date().toISOString().split('T')[0] }
                    : proc
            )
        );
    };
    
    

    
    const handlePatientInfoChange = (e) => {
        setPatientInfoChanged(true);
        const { name, value } = e.target;
        setPatientInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const filteredProcedures = assignedProcedures?.filter(
        procedure => RolesTranslated[procedure.doctor] && RolesTranslated[procedure.doctor] === role
    );

    const mutation = useMutation({
        mutationFn: async (updatedProcedures) => {
          const api_route = `/api/contract/${patients[selectedTab].contract_id}/patients/${patients[selectedTab]._id}`;
        //   console.log(comment);
        //   console.log(patientInfoChanged);
          
          if ( comment )
            await axiosPrivate.patch(`${api_route}/comment`, { comment_content: comment });
          if ( patientInfoChanged)
            await axiosPrivate.patch(`${api_route}/info`, { patient_info: patientInfo });

          const response = await axiosPrivate.patch(`${api_route}/procedures`, { assigned: updatedProcedures });

          return response.data;
        },
        onSuccess: () => {
          queryClient.invalidateQueries(['patients']);
          toast.success('Пациент успешно обновлен');
          onClose();
        },
        onError: () => {
          toast.error('Ошибка при обновлении пациента');
        }
      });

    const handleConfirm = () => {
        mutation.mutate(assignedProcedures);
    };

    const handleRoleSpecificAction = () => {
        const currentDate = new Date().toISOString().split('T')[0];
        const updatedProcedures = assignedProcedures?.map(proc => ({
            ...proc,
            date:
                RolesTranslated[proc.doctor] === role
                    ? currentDate
                    : proc.date,
        }));
        // console.log(updatedProcedures);

        // console.log(patientInfo);
        mutation.mutate(updatedProcedures);
    };
    

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Информация о пациенте</DialogTitle>
            <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
                {patients?.map((patient, index) => (
                    <Tab key={index} label={patient.fullname} />
                ))}
            </Tabs>
            <DialogContent>
                {patients?.length > 0 && (
                    <Box sx={{ borderLeft: '4px solid', borderColor: 'primary.main', paddingLeft: 2, marginBottom: 2 }}>
                        
                        <Typography>Компания Заказчик: {patients[selectedTab].company_enroller}</Typography>
                        <Typography sx={{ marginBottom: 2 }}>Дата контракта: {new Date(patients[selectedTab].date_of_contract).toLocaleDateString()}</Typography>
                        
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="ФИО"
                            name="fullname"
                            value={patientInfo.fullname}
                            onChange={handlePatientInfoChange}
                            sx={{ marginBottom: 2 }}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="ИИН"
                            name="iin"
                            value={patientInfo.iin}
                            onChange={handlePatientInfoChange}
                            sx={{ marginBottom: 2 }}
                        />
                        {/* <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={patientInfo.gender}
                            label="Пол"
                            name="gender"
                            sx={{ marginBottom: 2 }}
                            onChange={(e) => handlePatientInfoChange(e.target.value)}
                        >
                            <MenuItem value="Male">Мужской</MenuItem>
                            <MenuItem  value="Female">Женский</MenuItem>
                            <MenuItem value="">-</MenuItem>
                        </Select> */}
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Дата рождения"
                            name="dob"
                            type="date"
                            value={patientInfo.dob ? correctDateForTimezone(patientInfo.dob) : ""}
                            onChange={handlePatientInfoChange}
                            sx={{ marginBottom: 2 }}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Должность"
                            name="position"
                            value={patientInfo.position}
                            onChange={handlePatientInfoChange}
                            sx={{ marginBottom: 2 }}
                        />

                    </Box>
                )}

                {role !== 'registrator' && (
                    <>
                        <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 1 }}>Прогресс:</Typography>
                        <ProgressBar assigned={assignedProcedures}/>
                    </>
                )}

                <Box>
                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                        Процедуры:
                        <IconButton onClick={handleExpandClick} size="small">
                            <ExpandMoreIcon />
                        </IconButton>
                    </Typography>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        {filteredProcedures.map((procedure, index) => {
                            const isCompleted = procedure.date !== null; // Adjust based on your data structure
                            const label = `${procedure.procedure}: ${isCompleted ? 'Пройдено' : 'Не пройдено'}`;
                            return (
                                <Box key={index} display="flex" alignItems="center">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isCompleted}
                                                onChange={() => handleCheckboxChange(procedure.procedure)}
                                                disabled={role === "registrator"}
                                            />
                                        }
                                        label={label}
                                    />
                                </Box>
                            );
                        })}
                        {!!specialistsProcedures[role] && (
                            <Button onClick={() => checkAllProcedures(filteredProcedures)} color="success" variant="outlined">
                                Отметить все процедуры
                            </Button>
                        )}
                        
                        {(role === 'therapist' || role === "admin") && (
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Добавить процедуру"
                                    value={newProcedure}
                                    onChange={(e) => setNewProcedure(e.target.value)}
                                    sx={{ marginRight: 2 }}
                                />
                                <Button variant="contained" onClick={handleAddProcedure}>Добавить</Button>
                            </Box>
                        )}
                    </Collapse>
                </Box>
            </DialogContent>
            <Box sx={{ padding: 2 }}>
                <TextField
                    fullWidth
                    label="Комментарий"
                    variant="outlined"
                    multiline
                    rows={3}
                    value={comment || ''}
                    onChange={(e) => setComment(e.target.value)}
                />
            </Box>

            <DialogActions>
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start', paddingLeft: 1 }}>
                {!!specialistsProcedures[role] && (
                    <Button onClick={handleRoleSpecificAction} color="success" variant="outlined">
                        Отметить все и Сохранить
                    </Button>
                )}
                </Box>
                
                <Button onClick={onClose}>Назад</Button>
                {role === 'registrator' && (
                    <Button onClick={handleRoleSpecificAction} color="success" variant="contained">
                        Отметить пациента
                    </Button>
                )}
                {(!!specialistsProcedures[role] || role === 'therapist' || role === "admin") && (
                    <Button onClick={handleConfirm} color="success" variant="contained">
                        Сохранить изменения
                    </Button>
                )}
                
            </DialogActions>
        </Dialog>
    );
};

export default PatientModalTabs;
