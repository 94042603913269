import * as xlsx from 'xlsx';

const processSpreadsheet = (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onload = (event) => {
            try {
                const data = new Uint8Array(event.target.result);
                const workbook = xlsx.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                const rows = xlsx.utils.sheet_to_json(sheet, { header: 1 });

                // Find the header row index
                let headerRowIndex = rows.findIndex(
                    (row) => Array.isArray(row) && row.includes('Ф.И.О.') && row.includes('Дата рождения')
                );

                if (headerRowIndex === -1) {
                    throw new Error('Не удалось найти заголовки таблицы.');
                }

                const headers = mapHeaders(rows[headerRowIndex]);
                if (headers.includes(null)) {
                    throw new Error('Некоторые заголовки таблицы не распознаны.');
                }

                // Transform rows into objects
                const patients = rows
                    .slice(headerRowIndex + 1)
                    .filter((row) => Array.isArray(row) && row.length > 0)
                    .map((row) => {
                        const patient = {};
                        headers.forEach((field, index) => {
                            if (!field) return;
                            if (field === 'gender') {
                                patient[field] = parseGender(row[index]);
                            } else if (field === 'dob' || field === 'do_last_medrev' || field === 'do_this_medrev') {
                                patient[field] = parseDate(row[index]);
                            } else {
                                patient[field] = row[index] || null;
                            }
                        });
                        return patient;
                    });


                resolve(patients);
            } catch (error) {
                reject(error.message);
            }
        };

        reader.onerror = (error) => {
            reject('Error reading file.');
        };

        reader.readAsArrayBuffer(file);
    });
};

// Helpers remain unchanged
const mapHeaders = (headers) => {
    const headerMapping = {
        '№ п/п': 'id',
        'Табельный номер': 'id',
        'ИИН': 'iin',
        'Ф.И.О.': 'fullname',
        'ФИО': 'fullname',
        'Дата рождения': 'dob',
        'Пол': 'gender',
        'Объект/участок': 'object',
        'Объект или участок': 'object',
        'Занимаемая должность': 'position',
        'Должность': 'position',
        'Дата последнего медосмотра': 'do_last_medrev',
        'Стаж работы': 'work_experience',
        'Общий стаж': 'work_experience',
        'Профессиональная вредность': 'occupational_hazard',
        'Дата прохождения медосмотра': 'do_this_medrev',
        'Примечание': 'do_this_medrev'

    };

    return headers.map((header) => headerMapping[header] || null);
};

const parseDate = (value) => {
    if (!value) return null;

    if (typeof value === 'number') {
        // Excel date serial numbers: Convert to JS Date
        const excelEpoch = new Date(1899, 11, 31); // Excel starts from 1900-01-01, but JS starts from 1899-12-30
        const daysOffset = Math.floor(value);
        const msInDay = 24 * 60 * 60 * 1000;
        const date = new Date(excelEpoch.getTime() + daysOffset * msInDay);
        return date.toISOString().slice(0, 10); // Format as 'YYYY-MM-DD'
    }

    // If not a number, try parsing as a regular date string
    const date = new Date(value);
    if (isNaN(date.getTime())) {
        return null; // Invalid date
    }
    const res = date.toISOString().slice(0, 10);

    return res; // Format as 'YYYY-MM-DD'
};


const parseGender = (value) => {
    if (!value) return null;
    const lowerValue = value.toString().toLowerCase();
    if (['мужской', 'муж', 'm', 'male'].includes(lowerValue)) return 'Male';
    if (['женский', 'жен','f', 'female'].includes(lowerValue)) return 'Female';
    return null; // Unknown or invalid gender
};


export default processSpreadsheet;
