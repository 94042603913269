import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import UploadPatients from '../features/patients/UploadPatients';



const AddContractPage = () => {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    // Function to fetch companies
    const fetchCompanies = async () => {
        const response = await axiosPrivate.get('/api/contract/companies');
        console.log(response.data);
        return response.data;
    };
    // Fetch companies data using React Query
    const { data: companies, isLoading, isError, refetch } = useQuery({
        queryKey: ['companies'],
        queryFn: fetchCompanies,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });

    const handleUploadSuccess = () => {
        navigate(-1); // Navigate back to the previous page
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" sx={{ marginBottom: 3 }}>Загрузка пациентов</Typography>
            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Typography color="error">Ошибка при загрузке данных</Typography>
            ) : (
                <UploadPatients companies={companies} onUploadSuccess={handleUploadSuccess} onBackButton={() => navigate(-1)}/>
            )}
        </Box>
    );
};

export default AddContractPage;
