import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Box, Collapse, IconButton, Checkbox, FormControlLabel } from '@mui/material';

import {
    Table,
    Header,
    HeaderRow,
    Body,
    Row,
    HeaderCell,
    Cell,
  } from "@table-library/react-table-library/table";

import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';


import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const ProceduresEditModal = ({ open, onClose, procedures, setProcedures  }) => {
    const materialTheme = getTheme(DEFAULT_OPTIONS);
    const theme = useTheme(materialTheme);
    const PROCEDURES_COLUMNS = [
        'Процедура',
        'Врач',
        'Пол',
        'Действия'
    ];
    const emptyProcedure = {
        procedure: '',
        doctor: '',
        gender: '',
    };
    
    const doctorList = [
        '-',
        'Регистратор',
        'Терапевт',
        'Офтальмолог',
        'Отоларинголог',
        'Хирург',
        'Невролог',
        'Дерматолог',
        'Гинеколог',
        'Кардиолог',
    ]

    const handleAddRow = (table, setTable, emptyRow) => {
        setTable([...table, emptyRow ]);
    };
    const handleDeleteRow = (table, setTable, index) => {
        const newTable = [...table];
        newTable.splice(index, 1);
        setTable(newTable);
    };

    
    const handleUpdate = (setTable, value, id, property) => {
        setTable((state) =>
            state.map((row, index) => {
                if (index === id) {
                    return { ...row, [property]: value };
                }
                return row;
            })
        );
    };
    
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Изменение процедур:</DialogTitle>
            <DialogContent>
                <Box>
                    <Table data={{ nodes: procedures }}  theme={theme}>
                    {(tableList) => (
                        <>
                        <Header>
                            <HeaderRow>
                            {PROCEDURES_COLUMNS.map((column, col_index) => (
                                <HeaderCell key={col_index}>{column}</HeaderCell>
                            ))}
                            </HeaderRow>
                        </Header>

                        <Body>
                            {tableList.map((item, index) => (
                            <Row key={index} item={item}>
                                <Cell>
                                <input
                                    type="text"
                                    value={item.procedure}
                                    onChange={(e) => handleUpdate(setProcedures, e.target.value, index, 'procedure')}
                                    style={{ width: "100%"}}
                                />
                                </Cell>
                                <Cell>
                                <select
                                    value={item.doctor}
                                    onChange={(e) => handleUpdate(setProcedures, e.target.value, index, "doctor")}
                                    style={{ width: "100%", border: "none" }}
                                >
                                    {doctorList.map((doctor) => (
                                        <option value={doctor}>{doctor}</option>
                                    ))}
                                </select>
                                </Cell>
                                <Cell>
                                <select
                                    value={item.gender}
                                    onChange={(e) => handleUpdate(setProcedures, e.target.value, index, "gender")}
                                    style={{ width: "100%", border: "none" }}
                                >
                                    <option value="Male">Муж</option>
                                    <option value="Female">Жен</option>
                                    <option value="">-</option>
                                </select>
                                </Cell>
                                <Cell>
                                <IconButton onClick={() => handleDeleteRow(procedures, setProcedures, index)}>
                                    <DeleteIcon />
                                </IconButton>
                                </Cell>
                            </Row>
                            ))}
                        </Body>
                        </>
                    )}
                    </Table>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={() => handleAddRow(procedures, setProcedures, emptyProcedure)}>
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>Назад</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProceduresEditModal;