import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import UpdatePatients from '../features/patients/UpdatePatients';



const UpdateContractPage = () => {
    const {contract_id} = useParams();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    // Function to fetch companies
    const fetchContractById = async () => {
        const response = await axiosPrivate.get(`/api/contract/${contract_id}`);
        return response.data;
    }
    // Fetch companies data using React Query
    const { data: contract_info, isLoading: isContractLoading, isError: isContractError, refetch: refetchContract } = useQuery({
        queryKey: ['contract_info', contract_id],
        queryFn: fetchContractById,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });
    const fetchCompanies = async () => {
        const response = await axiosPrivate.get('/api/contract/companies');
        return response.data;
    };
    // Fetch companies data using React Query
    const { data: companies, isLoading, isError, refetch } = useQuery({
        queryKey: ['companies'],
        queryFn: fetchCompanies,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });

    const handleUploadSuccess = () => {
        navigate(-1); // Navigate back to the previous page
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" sx={{ marginBottom: 3 }}>Загрузка пациентов</Typography>
            {isLoading || isContractLoading ? (
                <CircularProgress />
            ) : isError || isContractError ? (
                <Typography color="error">Ошибка при загрузке данных</Typography>
            ) : (
                <UpdatePatients contract_info={contract_info} companies={companies} onUploadSuccess={handleUploadSuccess} onBackButton={() => navigate(-1)}/>
            )}
        </Box>
    );
};

export default UpdateContractPage;
