import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import PatientTable from '../features/patients/PatientTable';
import PatientDetails from '../features/patients/PatientDetails';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import PatientModal from "../features/patients/PatientModal";

// Function to fetch contracts data


const AdminPage = () => {
    const axiosPrivate = useAxiosPrivate();
    const [selectedPatient, setSelectedPatient] = useState(null);
    const navigate = useNavigate();
    const role = "admin";

    useEffect(() => {
        refetch();
    }, []);


    //  Take out in the onRead
    const [patientModalOpen, setPatientModalOpen] = useState(false);
    const handlePatientModalClose = () => {
        setPatientModalOpen(false);
    };
    const handleRead = (patient) => {
        setSelectedPatient(patient);
        setPatientModalOpen(true);
    };
    const handleDelete = async (patient) => {
        await axiosPrivate.put(`/api/contract/delete-patient/${patient.contract_id}`, {
            iin: patient.iin
        });
        refetch(); // Refetch contracts data after delete
        if (selectedPatient && selectedPatient.iin === patient.iin) {
            setSelectedPatient(null);
        }
    }

    const handleUpdateContract = (id) => {
        navigate(`update/${id}`);
    }



    const fetchContracts = async () => {
        const response = await axiosPrivate.get('/api/contract');
        return response.data;
    };

    // Fetch contracts data using React Query
    const { data: contracts, isLoading, isError, refetch } = useQuery({
        queryKey: ['contracts'],
        queryFn: fetchContracts,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });

    // Combine all patients from different contracts
    const patients = contracts ? contracts.flatMap(contract => 
        contract.patients.map(patient => ({
            ...patient,
            company_enroller: contract.company_enroller,
            contract_id: contract._id,
            date_of_contract: contract.date_of_contract
        }))
    ) : [];

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" sx={{ marginBottom: 3 }}>Панель Управления</Typography>
            <Button variant="contained" color="primary" onClick={() => navigate('upload')} sx={{ marginBottom: 3 }}>
                Загрузить пациентов
            </Button>
            {isLoading ? (
                <Typography>Загрузка...</Typography>
            ) : isError ? (
                <Typography color="error">Ошибка при загрузке данных</Typography>
            ) : (
                <PatientTable role={role} patients={patients} onRead={handleRead} onDelete={handleDelete} onContractUpdate={handleUpdateContract}/>
            )}
            {/* {selectedPatient && <PatientDetails patient={selectedPatient} />} */}
            

            {selectedPatient && (
                <PatientModal
                    open={patientModalOpen}
                    onClose={handlePatientModalClose}
                    patient={selectedPatient}
                    role={role}
                    onRoleSpecificAction={() => {}} // Implement the specific action for admin role
                />
            )}
        </Box>
    );
};

export default AdminPage;
