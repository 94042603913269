import { LinearProgress } from "@mui/material";

const ProgressBar = ({ assigned }) => {
    const calculateProgress = (assignedProcedures) => {
        const totalProcedures = assignedProcedures.length;
        const completedProcedures = assignedProcedures.filter(
            procedure => procedure.date !== null
        ).length;
        
        return totalProcedures > 0 
            ? (completedProcedures / totalProcedures) * 100 
            : 0;
    };

    return (
        <LinearProgress 
            variant="determinate" 
            color="success" 
            value={calculateProgress(assigned)} 
            sx={{ marginBottom: 2 }} 
        />
    );
};

export default ProgressBar;
