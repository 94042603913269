import React, { useEffect, useState } from 'react';
import {  Accordion, AccordionSummary, AccordionDetails, Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Paper, Select,  TextField, Typography, IconButton, Hidden, Collapse, List, ListItem, ListItemText } from '@mui/material';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OutboundIcon from '@mui/icons-material/Outbound';
import processSpreadsheet from "./UploadTableXLSX";

import {
    Table,
    Header,
    HeaderRow,
    Body,
    Row,
    HeaderCell,
    Cell,
  } from "@table-library/react-table-library/table";

import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';
import ProceduresEditModal from './ProceduresEditModal';



// helper utility
const correctDateForTimezone = (dateString) => {
    const date = new Date(dateString);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset).toISOString().slice(0, 10);
};

const emptyPatient = {
    id: '', 
    iin: '', 
    fullname: '', 
    dob: '', 
    gender: '', 
    object: '',
    position: '',
    work_experience: '',
    do_last_medrev: '',
    occupational_hazard: '',
    do_this_medrev: '', 
    assigned: [] 
};


//  change naming of the component
const UploadPatients = ({ companies, onUploadSuccess, onBackButton }) => {    
    const axiosPrivate = useAxiosPrivate();
    const materialTheme = getTheme(DEFAULT_OPTIONS);
    const theme = useTheme(materialTheme);
    const [newCompany, setNewCompany] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');
    const [procedures, setProcedures] = useState([
        {
            procedure: 'Регистратор', 
            doctor: 'Регистратор', 
            gender: ''
        },
        {
            procedure: 'Терапевт', 
            doctor: 'Терапевт', 
            gender: ''
        },
        {
            procedure: 'Офтальмолог', 
            doctor: 'Офтальмолог', 
            gender: ''
        },
        {
            procedure: 'Отоларинголог', 
            doctor: 'Отоларинголог', 
            gender: ''
        },
        {
            procedure: 'Хирург', 
            doctor: 'Хирург', 
            gender: ''
        },
        {
            procedure: 'Невролог', 
            doctor: 'Невролог', 
            gender: ''
        },
        {
            procedure: 'Дерматолог', 
            doctor: 'Дерматолог', 
            gender: ''
        },
        {
            procedure: 'ОАК', 
            doctor: '', 
            gender: ''
        },
        {
            procedure: 'ОАМ', 
            doctor: '', 
            gender: ''
        },
        {
            procedure: 'ЭКГ', 
            doctor: 'Кардиолог', 
            gender: ''
        },
        {
            procedure: 'Флюрография', 
            doctor: '', 
            gender: ''
        },
        {
            procedure: 'Аудиометрия', 
            doctor: '', 
            gender: ''
        },
        {
            procedure: 'Спирография', 
            doctor: '', 
            gender: ''
        },
        {
            procedure: 'Гинеколог', 
            doctor: 'Гинеколог', 
            gender: 'Female'
        },
    ]);
    const [patients, setPatients] = useState([emptyPatient]);
    const [duplicateMessages, setDuplicateMessages] = useState([]);
    const [isDuplicateMessagesExpanded, setIsDuplicateMessagesExpanded] = useState(true);


    const [proceduresEditModalOpen, setProceduresEditModalOpen] = useState(false);

    const COLUMNS = [
        'No.',
        'ИИН',
        'ФИО',
        'Дата Рождения',
        'Пол', 
        'Объект/участок',
        'Должность',   
        'Стаж работы',     
        'Дата последнего медосмотра',
        'Проф вредность',     
        'Дата прохождения медосмотра',
        'Действия',
        ];

    




    // Unclear moments of creating the contract record
    const [dateOfPayment, setDateOfPayment] = useState(''); // Differentiator of contract (should be changed 100%)

    // Procedures
    const [additionalProcedures, setAdditionalProcedures] = useState([]);
    const [newProcedure, setNewProcedure] = useState('');
    
    const [errors, setErrors] = useState({});
    const [focusedField, setFocusedField] = useState(null);

    const notifyDuplicatePatients = (patients) => {
        const duplicates = [];
        const nameToDetailsMap = new Map();
    
        patients.forEach((patient, index) => {
            const { fullname, dob, position } = patient;
            const fullKey = `${fullname}-${dob}-${position}`;
            const nameKey = fullname;
    
            if (nameToDetailsMap.has(nameKey)) {
                const existingEntries = nameToDetailsMap.get(nameKey);
    
                // Check for full duplicate
                const fullDuplicate = existingEntries.find(
                    (entry) => entry.dob === dob && entry.position === position
                );
                if (fullDuplicate) {
                    duplicates.push(
                        `Полный дубликат: "${fullname}" (ДР: ${dob}, Должность: ${position}). ` +
                        `Исходная строка: ${fullDuplicate.index + 1}, повтор: ${index + 1}.`
                    );
                    return; // Skip further checks for this patient
                }
    
                // Check for same name but different DOB
                const dobMismatch = existingEntries.find(
                    (entry) => entry.dob !== dob && entry.position === position
                );
                if (dobMismatch) {
                    duplicates.push(
                        `Разные даты рождения: "${fullname}" имеет разные даты рождения (ДР: ${dob} и ${dobMismatch.dob}). ` +
                        `Исходная строка: ${dobMismatch.index + 1}, повтор: ${index + 1}.`
                    );
                }
    
                // Check for same name but different position
                const positionMismatch = existingEntries.find(
                    (entry) => entry.dob === dob && entry.position !== position
                );
                if (positionMismatch) {
                    duplicates.push(
                        `Разные должности: "${fullname}" (ДР: ${dob}) имеет разные должности (Должность: ${position} и ${positionMismatch.position}). ` +
                        `Исходная строка: ${positionMismatch.index + 1}, повтор: ${index + 1}.`
                    );
                }
            } else {
                nameToDetailsMap.set(nameKey, []);
            }
    
            // Add current patient to the map
            nameToDetailsMap.get(nameKey).push({ dob, position, index });
        });
    
        setDuplicateMessages(duplicates);
    };
    

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            const importedPatients = await processSpreadsheet(file);
            setPatients(importedPatients); // Update the table data
            notifyDuplicatePatients(importedPatients); // Call the duplicate check
        } catch (error) {
            console.error("Error importing spreadsheet:", error);
            alert("Ошибка при импорте таблицы. Убедитесь, что файл корректен.");
        }
    };
    
    const handleUpload = async () => {
        // if (!validateFields()) {
        //     toast.error('Пожалуйста, исправьте ошибки перед отправкой');
        //     return;
        // }
        const patientsWithProcedures = patients.map(patient => ({
            ...patient,
            assigned: procedures.filter(procedure => 
                !procedure.gender || procedure.gender === patient.gender // Include if gender is not specified or matches the patient's gender
            )
        }));
        

        const data = {
            company_enroller: selectedCompany || newCompany,
            patients: patientsWithProcedures,
            date_of_contract: dateOfPayment,
            procedures_by_contract: procedures
        };

        try {
            // console.log(data);
            const response = await axiosPrivate.post('/api/contract', data);
            toast.success('Пациенты успешно загружены');
            onUploadSuccess();
        } catch (error) {
            if (error.response && error.response.status === 409) {
                toast.error('Контракт для этой компании и даты уже существует.');
            } else {
                toast.error('Ошибка при загрузке пациентов');
            }
        }
    };

    const handlePaste = (event, rowIndex, fieldKey) => {
        event.preventDefault();
        const clipboardData = event.clipboardData.getData('text/plain');
        const rows = clipboardData.split(/\r\n|\n/); // Split lines
        const newPatients = [...patients];
    
        rows.forEach((row, i) => {
            const values = row.split('\t'); // Assuming tab-separated values from Excel
            let patientIndex = rowIndex + i;
            if (patientIndex >= newPatients.length) {
                // Add new empty patient entries if needed
                newPatients.push({ iin: '', fullname: '', dob: '', gender: '', position: '', assigned: {} });
            }
    
            let patient = newPatients[patientIndex];
            const fields = ['iin', 'fullname', 'dob', 'gender', 'position']; // Fields in order of appearance in the table
            let fieldIndex = fields.indexOf(fieldKey);
    
            values.forEach((value, valueIndex) => {
                let key = fields[fieldIndex + valueIndex];
                if (!key) return; // Skip if there's no corresponding field
    
                // Handle specific field cases
                if (key === 'dob') {
                    patient[key] = correctDateForTimezone(value);
                } else if (key === 'gender') {
                    patient[key] = value === 'M' ? 'Мужской' : value === 'F' ? 'Женский' : value;
                } else {
                    patient[key] = value;
                }
            });
        });
    
        setPatients(newPatients);
    };
    
    const handleAddRow = (table, setTable, emptyRow) => {
        setTable([...table, emptyRow ]);
    };
    const handleDeleteRow = (table, setTable, index) => {
        const newTable = [...table];
        newTable.splice(index, 1);
        setTable(newTable);
    };

    
    const handleUpdate = (setTable, value, id, property) => {
        setTable((state) =>
            state.map((row, index) => {
                if (index === id) {
                    return { ...row, [property]: value };
                }
                return row;
            })
        );
    };
    

    // too much rendering - make into separate components JSX or smth
    return (
        <Paper elevation={3} sx={{ padding: 3, margin: 2 }}>
            <FormControl fullWidth >
                <Autocomplete
                    value={selectedCompany}
                    onChange={(event, newValue) => {
                        setSelectedCompany(newValue);
                    }}
                    inputValue={newCompany}
                    onInputChange={(event, newInputValue) => {
                        setNewCompany(newInputValue || ''); // Set to empty string if undefined or null
                    }}
                    freeSolo
                    options={companies}
                    renderInput={(params) => (
                        <TextField {...params} label="Выберите Компанию Заказчика" variant="outlined" />
                    )}
                />
            </FormControl>
            <TextField
                fullWidth
                variant="outlined"
                label="Дата заключения договора"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={dateOfPayment}
                onChange={(e) => setDateOfPayment(e.target.value)}
                error={!!errors.dateOfPayment}
                helperText={errors.dateOfPayment}
                sx={{ marginTop: 2 }}
            />
            {/* Add the upload button here */}
            <input
                type="file"
                accept=".xlsx" // Specify accepting only XLSX files
                onChange={handleFileUpload}
                id="upload-button"
                style={{ display: 'none' }}
            />
            <label htmlFor="upload-button" >
                <Button variant="contained" component="span" sx={{ marginTop: 4, marginBottom: 2 }}>
                Загрузить таблицу (XLSX)
                </Button>
            </label>
            {/* <Typography variant="h6" >Список пациентов:</Typography> */}
            
            {duplicateMessages && duplicateMessages.length > 0 && (
            <Box mt={2} >
                <Button
                    variant="outlined"
                    onClick={() => setIsDuplicateMessagesExpanded(!isDuplicateMessagesExpanded)}
                    size="small"
                >
                    {isDuplicateMessagesExpanded ? "Скрыть дубликаты" : "Показать дубликаты"}
                </Button>
                <Collapse in={isDuplicateMessagesExpanded}>
                    <Box
                        mt={2}
                        p={2}
                        border={1}
                        borderColor="grey.300"
                        borderRadius={1}
                        bgcolor="#FFFFDB"
                    >
                        {duplicateMessages.length > 0 ? (
                            <List>
                                {duplicateMessages.map((message, index) => (
                                    <ListItem key={index} disablePadding>
                                        <ListItemText primary={message} />
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                Дубликатов не найдено.
                            </Typography>
                        )}
                    </Box>
                </Collapse>
            </Box>
            )}
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {/* <IconButton onClick={() => onContractUpdate(group.contract_id)} sx={{paddingTop: 0}}>
                        <OutboundIcon sx={{maxHeight:1}} />
                    </IconButton> */}
                    <Typography>Список Пациентов:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* THE TABLE HERE */}
                    <Table data={{ nodes: patients }}  theme={theme}>
                    {(tableList) => (
                        <>
                        <Header>
                            <HeaderRow>
                            {COLUMNS.map((column, col_index) => (
                                <HeaderCell key={col_index}>{column}</HeaderCell>
                            ))}
                            </HeaderRow>
                        </Header>

                        <Body>
                            {tableList.map((item, index) => (
                            <Row key={index} item={item}>
                                <Cell>
                                <input
                                    type="text"
                                    value={item.id}
                                    onPaste={(e) => handlePaste(e, index, 'id')}
                                    onChange={(e) => handleUpdate(setPatients, e.target.value, index, 'id')}
                                    style={{ width: "100%"}}
                                />
                                </Cell>
                                <Cell>
                                <input
                                    type="text"
                                    value={item.iin}
                                    onPaste={(e) => handlePaste(e, index, 'iin')}
                                    onChange={(e) => handleUpdate(setPatients, e.target.value, index, 'iin')}
                                    style={{ width: "100%"}}
                                />
                                </Cell>
                                <Cell>
                                <input
                                    type="text"
                                    value={item.fullname}
                                    onPaste={(e) => handlePaste(e, index, 'fullname')}
                                    onChange={(e) => handleUpdate(setPatients, e.target.value, index, "fullname")}
                                    style={{ width: "100%"}}
                                />
                                </Cell>
                                <Cell>
                                <input
                                    type="date"
                                    value={item.dob}
                                    onPaste={(e) => handlePaste(e, index, 'dob')}
                                    onChange={(e) => handleUpdate(setPatients, e.target.value, index, "dob")}
                                    style={{ width: "100%"}}
                                />
                                </Cell>
                                <Cell>
                                <select
                                    value={item.gender}
                                    onPaste={(e) => handlePaste(e, index, 'gender')}
                                    onChange={(e) => handleUpdate(setPatients, e.target.value, index, "gender")}
                                    style={{ width: "100%"}}
                                >
                                    <option value="Male">Мужской</option>
                                    <option value="Female">Женский</option>
                                </select>
                                </Cell>
                                <Cell>
                                <input
                                    type="text"
                                    value={item.object}
                                    onPaste={(e) => handlePaste(e, index, 'object')}
                                    onChange={(e) => handleUpdate(setPatients, e.target.value, index, "object")}
                                    style={{ width: "100%"}}
                                />
                                </Cell>
                                <Cell>
                                <input
                                    type="text"
                                    value={item.position}
                                    onPaste={(e) => handlePaste(e, index, 'position')}
                                    onChange={(e) => handleUpdate(setPatients, e.target.value, index, "position")}
                                    style={{ width: "100%"}}
                                />
                                </Cell>
                                <Cell>
                                <input
                                    type="text"
                                    value={item.work_experience}
                                    onPaste={(e) => handlePaste(e, index, 'work_experience')}
                                    onChange={(e) => handleUpdate(setPatients, e.target.value, index, "work_experience")}
                                    style={{ width: "100%"}}
                                />
                                </Cell>
                                <Cell>
                                <input
                                    type="date"
                                    value={item.do_last_medrev}
                                    onPaste={(e) => handlePaste(e, index, 'do_last_medrev')}
                                    onChange={(e) => handleUpdate(setPatients, e.target.value, index, "do_last_medrev")}
                                    style={{ width: "100%"}}
                                />
                                </Cell>
                                <Cell>
                                <input
                                    type="text"
                                    value={item.occupational_hazard}
                                    onPaste={(e) => handlePaste(e, index, 'occupational_hazard')}
                                    onChange={(e) => handleUpdate(setPatients, e.target.value, index, "occupational_hazard")}
                                    style={{ width: "100%"}}
                                />
                                </Cell>
                                <Cell>
                                <input
                                    type="date"
                                    value={item.do_this_medrev}
                                    onPaste={(e) => handlePaste(e, index, 'do_this_medrev')}
                                    onChange={(e) => handleUpdate(setPatients, e.target.value, index, "do_this_medrev")}
                                    style={{ width: "100%"}}
                                />
                                </Cell>
                                <Cell>
                                <IconButton onClick={() => handleDeleteRow(patients, setPatients, index)}>
                                    <DeleteIcon />
                                </IconButton>
                                </Cell>
                            </Row>
                            ))}
                        </Body>
                        </>
                    )}
                    </Table>
                    {/* THE TABLE END HERE */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={() => handleAddRow(patients, setPatients, emptyPatient)}>
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </Box>

                </AccordionDetails>
            </Accordion>

            
            

            <Typography variant="h6" sx={{ marginTop: 2 }}>
                Процедуры: 
                <IconButton onClick={() => setProceduresEditModalOpen(true)}>
                    <OutboundIcon />
                </IconButton>
            </Typography>
            {procedures.map((procedure, index) => (
                <Typography key={index} variant="body1">- {procedure.procedure} {procedure.gender? procedure.gender==="Male" ? "(для мужчин)" : "(для женщин)" : ""}</Typography>
            ))}

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                
            </Box>
            
            <Box>
                <ProceduresEditModal
                open={proceduresEditModalOpen}
                onClose={()=>setProceduresEditModalOpen(false)}
                procedures={procedures}
                setProcedures={setProcedures}

                />
            </Box>

            <Box fullWidth sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                <Button color='inherit' variant="contained" onClick={onBackButton}>Назад</Button>
                <Button color='success' variant="contained" onClick={handleUpload}>Загрузить</Button>
            </Box>
        </Paper>
    );
};


export default UploadPatients;
